import { Fragment, useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HeadInputs } from "../HeadInputs/HeadInputs";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Loading from "../Loading";
import { useNavigate, useParams } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { AddAttachmentQuestion } from "../../redux/apiCalls/subscribersApiCall";
import { request } from "../../utils/request";

const theme = createTheme({
  palette: {
    primary: {
      main: "#042128",
    },
    text: {
      primary: "#042128",
    },
  },
  typography: {
    fontFamily: "Tajawal, sans-serif", // Change the font family here
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          width: "100%",
        },
        inputRoot: {
          width: "100%",
          padding: "0",
          paddingRight: "0px !important",
        },
        input: {
          border: "1px solid #004556",
          padding: "10px 35px 10px 14px !important",
          borderRadius: "15px",
          "&:focus": {
            outline: "none !important",
          },
        },
        popupIndicator: {
          display: "none",
        },
      },
    },
  },
});

export const FormAddAtachment = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const addQuestion = useRef("");
  const [selectedAnswer, setSelectedAnswer] = useState();

  const answer = [
    { label: "الجواب a", value: "aAnswerImage" },
    { label: "الجواب b", value: "bAnswerImage" },
    { label: "الجواب c", value: "cAnswerImage" },
    { label: "الجواب d", value: "dAnswerImage" },
    { label: "الجواب e", value: "eAnswerImage" },
    { label: "نص السؤال", value: "questionImage" },
    { label: "نص المسألة", value: "issueImage" },
  ];
  const navigate = useNavigate();
  const handleAutocompleteChange4 = (event, value) => {
    if (value) {
      setSelectedAnswer(value.value);
    } else {
      setSelectedAnswer(""); // Handle case when value is cleared
    }
  };

  const loading = useSelector((state) => state.users.delete);
  const [image, setImage] = useState(null);
  const [data, setData] = useState();

  const GetQuestionByID = async (ID) => {
    try {
      const response = await request.get(`/questions/${ID}`);
      const data = response.data;
      setData(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetQuestionByID(id);
  }, [id]);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };
  const handelAddQuestion = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image);
    formData.append("id", id);
    formData.append("propertyName", selectedAnswer);
    console.log(formData);

    dispatch(AddAttachmentQuestion(formData)).then((data) => {
      GetQuestionByID(id);
    });
  };
  return (
    <Fragment>
      <HeadInputs title="إضافة ملحقات السؤال" />
      <div className="ques-add w-full flex items-start gap-[120px] bg-[white]  py-[70px] px-[80px] rounded-[15px] mt-[43px]">
        <div className="flex-1 flex flex-col gap-[20px]">
          <div className="flex flex-wrap gap-[8px]">
            {data?.aAnswerImage.url && (
              <div>
                <span className="text-[16px]">ملحق الجواب a</span>
                <img
                  className="w-[200px] py-[15px] px-[15px]"
                  src={data?.aAnswerImage.url}
                  alt=""
                />
              </div>
            )}
            {data?.bAnswerImage.url && (
              <div>
                <span className="text-[16px]">ملحق الجواب b</span>
                <img
                  className="w-[200px] py-[15px] px-[15px]"
                  src={data?.bAnswerImage.url}
                  alt=""
                />
              </div>
            )}
            {data?.cAnswerImage.url && (
              <div>
                <span className="text-[16px]">ملحق الجواب c</span>
                <img
                  className="w-[200px] py-[15px] px-[15px]"
                  src={data?.cAnswerImage.url}
                  alt=""
                />
              </div>
            )}
            {data?.dAnswerImage.url && (
              <div>
                <span className="text-[16px]">ملحق الجواب d</span>
                <img
                  className="w-[200px] py-[15px] px-[15px]"
                  src={data?.dAnswerImage.url}
                  alt=""
                />
              </div>
            )}
            {data?.eAnswerImage.url && (
              <div>
                <span className="text-[16px]">ملحق الجواب e</span>
                <img
                  className="w-[200px] py-[15px] px-[15px]"
                  src={data?.eAnswerImage.url}
                  alt=""
                />
              </div>
            )}
            {data?.issueImage.url && (
              <div>
                <span className="text-[16px]">ملحق نص المسألة</span>
                <img
                  className="w-[200px] py-[15px] px-[15px]"
                  src={data?.issueImage.url}
                  alt=""
                />
              </div>
            )}
            {data?.questionImage.url && (
              <div>
                <span className="text-[16px]">ملحق نص السؤال</span>
                <img
                  className="w-[200px] py-[15px] px-[15px]"
                  src={data?.questionImage.url}
                  alt=""
                />
              </div>
            )}
          </div>
          <div className="inputs flex-1">
            <form
              className="flex flex-col gap-[35px]"
              onSubmit={handelAddQuestion}
              ref={addQuestion}
            >
              <div className="box-input">
                <label
                  className="block w-fit mb-[12px] text-[21px] font-[500] text-black cursor-pointer"
                  htmlFor="الملحق التي تريد اضافته"
                >
                  الملحق التي تريد اضافته
                </label>
                <ThemeProvider theme={theme}>
                  <Autocomplete
                    disablePortal
                    id="المحافظة"
                    name="country"
                    options={answer}
                    value={selectedAnswer}
                    onChange={handleAutocompleteChange4}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          className: "hidden",
                        }}
                      />
                    )}
                  />
                </ThemeProvider>
              </div>
              <div className="box-input">
                <label
                  className="block w-fit mb-[12px] text-[21px] font-[500] text-black cursor-pointer"
                  htmlFor="تحميل صورة"
                >
                  تحميل صورة
                </label>
                <label
                  className="flex items-center gap-[8px] px-[14px] py-[10px] border-solid border-[1px] border-[#004556] text-input rounded-[15px]"
                  htmlFor="تحميل صورة"
                >
                  <img
                    className="w-[24px] h-[24px]"
                    src="/assests/questionImage/camera.svg"
                    alt=""
                  />
                  {image ? (
                    <span className="text-[14px] font-[500]">
                      تم اختيار صورة
                    </span>
                  ) : null}
                </label>
                <input
                  type="file"
                  className="hidden w-full px-[14px] py-[10px] border-solid border-[1px] border-[#004556] text-input rounded-[15px] cursor-text overflow-y-auto focus:outline-none"
                  id="تحميل صورة"
                  onChange={handleImageChange}
                />
              </div>
              <button className=" text-[white] group w-full py-[14px]  relative z-10 shadow-shadow rounded-[15px]">
                <span className="bg-main group-hover:scale-[1.01] transition duration-[0.2s] absolute left-0 top-0 w-full h-full rounded-[15px] z-[-2]"></span>
                {loading ? <Loading /> : "حفظ"}
              </button>
            </form>
          </div>
        </div>
        <div className="w-[280px] h-[252px] sticky top-[10px]">
          <img
            className="w-full h-full"
            src="/assests/imageInput/person.svg"
            alt=""
          />
        </div>
      </div>
    </Fragment>
  );
};
